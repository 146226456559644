import React from 'react';
import Experience from 'xml-renderer';

function abbreviateString(str, maxLength = 64) {
	const nonWhitespaceString = str
		.replace(/\t/g, '')
		.replace(/\n/g, '')
		.replace(/\s+(?=\s)/g, '')
		.trim();

	return nonWhitespaceString.length < maxLength
		? nonWhitespaceString
		: nonWhitespaceString.substr(0, maxLength - 1) + '…';
}

const experience = new Experience();

experience.register('self::node()', ({ traverse }) => traverse());

experience.register('self::text()', ({ node }) =>
<span><span style={{ color:'#ccc' }}>{'"'}</span>{node().nodeValue}<span style={{ color:'#ccc' }}>{'"'}</span></span>
);

experience.register('self::element()', ({ key, query, node }) => {
	const isSelfClosing = query('boolean(not(child::node()))');
	const name = query('name()');
	return (
		<span key={key()}>
			<span className="cm-tag cm-bracket">{'<'}</span>
			<span className="cm-tag">{name}</span>

			{Array.from(node().attributes).reduce(
				(concatenated, attribute) =>
					concatenated.concat([
						<span key={'space-' + attribute.name}> </span>,
						<DomNode node={attribute} key={'attr-' + attribute.name} />
					]),
				[]
			)}

			{isSelfClosing && <span className="cm-tag cm-bracket"> /</span>}

			<span className="cm-tag cm-bracket">{'>'}</span>

			{!isSelfClosing && (
				<span style={{ color: '#ccc' }}>{abbreviateString(node().innerHTML)}</span>
			)}

			{!isSelfClosing && (
				<span>
					<span className="cm-tag cm-bracket">{'</'}</span>
					<span className="cm-tag">{name}</span>
					<span className="cm-tag cm-bracket">{'>'}</span>
				</span>
			)}
		</span>
	);
});

experience.register('self::attribute()', ({ query }) => {
	const name = query('string(name())');
	return (
		<span key={name}>
			<span className="cm-attribute">{name}</span>
			<span>{'='}</span>
			<span className="cm-string">"{query('string(.)')}"</span>
		</span>
	);
});

experience.register('self::comment()', ({ key, node }) => {
	return (
		<span key={key()}>
			<span className="cm-comment">
				{'<!--'}
				{node().nodeValue}
				{'-->'}
			</span>
		</span>
	);
});

experience.register('self::processing-instruction()', ({ key, node }) => {
	return (
		<span key={key()} className="cm-meta">
			{'<?' + node().nodeName + ' ' + node().nodeValue + '?>'}
		</span>
	);
});

/**
 * Renders syntax highlighted XML for a DOM node as an inline, read-only. Element nodes show only a (length trimmed)
 * part of their child nodes.
 */
export default function DomNode({
	/**
	 * @required
	 * @type {Node|Attribute|ProcessingInstruction|Comment|Text}
	 */
	node,

	/**
	 * Any other data that might be passed into the rendering callbacks for nodes
	 */
	...props
}) {
	return (
		<span className="cm-s-default" style={{ fontFamily: 'monospace', fontSize: '13px' }}>
			{experience.render(node, props)}
		</span>
	);
}
