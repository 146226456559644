import React, { useEffect, useState } from 'react';

import { Flex } from 'fds/components';

import SandboxBuckets from './SandboxBuckets';
import SandboxCollapsible from './SandboxCollapsible';
import SandboxDependency from './SandboxDependencies';
import SandboxPendingUpdates from './SandboxPendingUpdates';
import SandboxQueryResults from './SandboxQueryResults';
import SandboxUpdatedXml from './SandboxUpdatedXml';

export default function SandboxOutputPanel({
	errorMessage,
	mode,
	xdmValue,
	dependencyMap,
	pendingUpdateList,
	updatedXml,
	xquery
}) {
	const [isXdmCollapsed, setIsXdmCollapsed] = useState(false);
	const [isDependenciesCollapsed, setIsDependenciesCollapsed] = useState(true);
	const [isPendingUpdatesCollapsed, setIsPendingUpdatesCollapsed] = useState(false);
	const [isUpdatedXmlCollapsed, setIsUpdatedXmlCollapsed] = useState(false);
	const [isBucketsCollapsed, setIsBucketsCollapsed] = useState(true);

	const isInUpdateFacility = mode === 2;
	useEffect(() => {
		setIsPendingUpdatesCollapsed(!isInUpdateFacility);
		setIsUpdatedXmlCollapsed(!isInUpdateFacility);
	}, [mode]);

	return (
		<Flex
			flexDirection="column"
			flex={'1'}
			applyCss={{
				zIndex: 2,
				overflow: 'hidden',
				display: errorMessage ? 'none' : 'visible'
			}}
		>
			<SandboxCollapsible
				headerContent="Query results"
				isCollapsed={isXdmCollapsed}
				onChange={() => setIsXdmCollapsed(!isXdmCollapsed)}
			>
				<SandboxQueryResults results={xdmValue} />
			</SandboxCollapsible>

			<SandboxCollapsible
				headerContent="Dependencies"
				isCollapsed={isDependenciesCollapsed}
				onChange={() => setIsDependenciesCollapsed(!isDependenciesCollapsed)}
			>
				<SandboxDependency dependencyMap={dependencyMap} />
			</SandboxCollapsible>

			<SandboxCollapsible
				headerContent="Pending updates"
				isCollapsed={isPendingUpdatesCollapsed}
				onChange={() => setIsPendingUpdatesCollapsed(!isPendingUpdatesCollapsed)}
			>
				<SandboxPendingUpdates
					updates={pendingUpdateList}
					isInUpdateFacility={isInUpdateFacility}
				/>
			</SandboxCollapsible>

			<SandboxCollapsible
				headerContent="Updated XML"
				isCollapsed={isUpdatedXmlCollapsed}
				onChange={() => setIsUpdatedXmlCollapsed(!isUpdatedXmlCollapsed)}
			>
				<SandboxUpdatedXml value={updatedXml} isInUpdateFacility={isInUpdateFacility} />
			</SandboxCollapsible>

			<SandboxCollapsible
				headerContent="Buckets"
				isCollapsed={isBucketsCollapsed}
				onChange={() => setIsBucketsCollapsed(!isBucketsCollapsed)}
			>
				<SandboxBuckets xquery={xquery} />
			</SandboxCollapsible>
		</Flex>
	);
}
