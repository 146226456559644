import React from 'react';
import { Flex } from 'fds/components';

/**
 * A section with a border around it. Serves as a visual wrapper for some fields and XPath results
 */
export default function Toast({
	/**
	 * True if the background and border should not be drawn.
	 *
	 * @optional
	 * @type {boolean}
	 */
	invisible,

	/**
	 * @optional
	 */
	children,

	/**
	 * Anything else is passed to the Flex component that is used.
	 */
	...flexProps
}) {
	return (
		<Flex
			flexDirection="column"
			flex="1"
			applyCss={{
				margin: '1px',
				paddingLeft: '5px',
				border: '1px solid ' + (invisible ? 'transparent' : '#ccc'),
				borderRadius: '2px',
				backgroundColor: invisible ? 'transparent' : '#fff',
				overflow: 'auto'
			}}
			{...flexProps}
		>
			{children}
		</Flex>
	);
}
