import React from 'react';
import { Flex, StateMessage, Block } from 'fds/components';
import XPathResult from './XPathResult';

function joinStrings(stringArray) {
	return stringArray.join(', ');
}

/**
 * Converts a dependency map to data accepted by an XPathResult component
 */
export default function SandboxDependency({ dependencyMap }) {
	if (!dependencyMap || dependencyMap.size === 0) {
		return (
			<StateMessage
				title="No dependencies"
				visual="check"
				message={'This means there is no dependency map'}
			/>
		);
	}

	const results = [];
	for (const entry of dependencyMap) {
		const key = entry[0];
		const values = entry[1];

		const types = [];
		const buckets = [];

		for (const key of Object.keys(values)) {
			if (values[key] === 'type') {
				types.push(key);
			} else if (values[key] === 'bucket') {
				buckets.push(key);
			}
		}

		results.push({
			node: key,
			[types.length > 1 ? 'types' : 'type']: joinStrings(types),
			[buckets.length > 1 ? 'buckets' : 'bucket']: joinStrings(buckets)
		});
	}

	return (
		<Block applyCss={{ width: '100%' }}>
			<Flex flexDirection="column" spaceSize="m" flex="0">
				{results.map((result, i) => (
					<XPathResult result={result} key={i} />
				))}
			</Flex>
		</Block>
	);
}
