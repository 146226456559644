import React from 'react';
import { Flex, StateMessage } from 'fds/components';

import XQueryUpdate from './XQueryUpdate';

/**
 * The contents of the panel that shows what pending updates were generated by fontoxpath#evaluateUpdatingExpression
 */
export default function SandboxPendingUpdates({
	/**
	 * A list of updates that were generated by fontoxpath#evaluateUpdatingExpression.
	 *
	 * @required
	 * @type {Array<{ type: String, target: Node, replacement: Node[] }>}
	 */
	updates,

	/**
	 * Wether or not the XQuery Update Facility mode is active. If so, a different state message is shown
	 *
	 * @optional
	 * @type {boolean}
	 */
	isInUpdateFacility
}) {
	if (!isInUpdateFacility) {
		return (
			<StateMessage
				title="No pending updates"
				visual="info"
				connotation="info"
				message={'Updates are only generated if you use XQuery Update Facility'}
			/>
		);
	}

	if (updates.length) {
		return (
			<Flex flexDirection="column" spaceSize="l">
				{updates.map((update, i) => (
					<div key={i}>
						<XQueryUpdate update={update} />
					</div>
				))}
			</Flex>
		);
	}
	return (
		<StateMessage
			title="No pending updates"
			visual="check"
			message={"That's weird, for someone who wants to use XQuery Update Facility"}
		/>
	);
}
