import React from 'react';
import { slimdom } from 'slimdom-sax-parser';
import { Label, KeyValueList } from 'fds/components';

import Toast from '../components/Toast';
import DomNode from '../components/DomNode';

/**
 * Render one result of an XPath or XQuery. Could be a normal string, number, boolean, object, array or something from
 * the DOM.
 */
export default function XPathResult({
	/**
	 * @optional
	 * @type {*}
	 */
	result
}) {
	if (
		!result ||
		typeof result === 'number' ||
		typeof result === 'string' ||
		typeof result === 'boolean'
	) {
		return (
			<Toast>
				<div>
					<Label tooltipContent="You are looking at the JSON stringified value.">
						{JSON.stringify(result)}
					</Label>
				</div>
			</Toast>
		);
	}

	if (Array.isArray(result)) {
		return (
			<div>
				<Label>Array with {result.length} items:</Label>
				{result.map((result, i) => (
					<XPathResult key={i} result={result} />
				))}
			</div>
		);
	}

	if (result instanceof slimdom.Document) {
		return (
			<Toast>
				<div>
					<Label tooltipContent="This is a document node.">#document</Label>
				</div>
			</Toast>
		);
	}

	if (
		result instanceof slimdom.Text ||
		result instanceof slimdom.Element ||
		result instanceof slimdom.Attr ||
		result instanceof slimdom.ProcessingInstruction ||
		result instanceof slimdom.Comment
	) {
		return (
			<Toast>
				<div>
					<DomNode node={result} />
				</div>
			</Toast>
		);
	}

	if (typeof result === 'object') {
		return (
			<Toast>
				<KeyValueList
					valueByKey={Object.keys(result).reduce(
						(obj, key) => ({
							...obj,
							[key]: <XPathResult result={result[key]} />
						}),
						{}
					)}
				/>
			</Toast>
		);
	}

	console.warn('The preview for a node was not configured', result, typeof result);

	// @TODO: Inspect objects

	return (
		<Toast>
			<div>
				<Label tooltipContent="This is the value constructor name">
					{result.constructor.name}
				</Label>
			</div>
		</Toast>
	);
}
