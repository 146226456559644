function makeExample(mode = 0, label, xml, context, xQuery, variables = {}) {
	return {
		mode,
		label,

		// Tabs are stripped out of xml, context and xQuery because they can be multiple lines and you might want to use tabs
		// for code formatting the JS config. For code formatting in XML, indent with spaces.
		xml: typeof xml === 'string' ? xml.replace(/\t/g, '').trim() : xml,
		contextQuery: context.replace(/\t/g, '').trim(),
		xQuery: xQuery.replace(/\t/g, '').trim(),

		variables: JSON.stringify(variables, null, '  ')
	};
}

const reusableSimpleXml = `
<xml>
	<title>xpath.playground.fontoxml.com</title>
	<summary>This is a learning tool for XML, XPath and XQuery.</summary>
	<tips>
		<tip id='edit'>You can edit everything on the left</tip>
		<tip id='examples'>You can access more examples from a menu in the top right</tip>
		<tip id='permalink'>Another button there lets you share your test using an URL</tip>
	</tips>
</xml>
`.replace(/\t/g, '  ');

const reusableLongerXml = `
<!-- https://www.w3schools.com/xml/xquery_example.asp -->
<bookstore>
	<book category="COOKING">
		<title lang="en">Everyday Italian</title>
		<author>Giada De Laurentiis</author>
		<year>2005</year>
		<price>30.00</price>
	</book>
	<book category="CHILDREN">
		<title lang="en">Harry Potter</title>
		<author>J K. Rowling</author>
		<year>2005</year>
		<price>29.99</price>
	</book>
	<book category="WEB">
		<title lang="en">XQuery Kick Start</title>
		<author>James McGovern</author>
		<author>Per Bothner</author>
		<author>Kurt Cagle</author>
		<author>James Linn</author>
		<author>Vaidyanathan Nagarajan</author>
		<year>2003</year>
		<price>49.99</price>
	</book>
	<book category="WEB">
		<title lang="en">Learning XML</title>
		<author>Erik T. Ray</author>
		<year>2003</year>
		<price>39.95</price>
	</book>
</bookstore>
`.replace(/\t/g, '  ');


const reusableDitaXml = `
<topic>
	<title>Simple Dita example</title>
	<body>
		<p class=" topic/p">This is not really Dita, but it looks a lot like it!</p>
	</body>
</topic>
`.replace(/\t/g, '  ');

const defaultExample = makeExample(0, 'Hello world', reusableSimpleXml, '', `//tip`);

export default {
	'default': defaultExample,
	'XPath': [
		defaultExample,

		makeExample(
			0,
			'Variables',
			reusableSimpleXml,
			'',
			`//tip[@id=$tipId or @id=$otherTip('id')]`,
			{ tipId: 'edit', otherTip: { id: 'permalink' } }
		),

		makeExample(
			0,
			'Maps and Arrays',
			reusableLongerXml,
			'',
			`/bookstore/book[price>=$maximumPrice]/map {
			  "Title": title,
			  "Category": @category,
			  "Authors": array { author },
			  "Price": concat('€', price)
			}`,
			{ maximumPrice: 30 }
		),

		makeExample(0, 'Changing the context', reusableSimpleXml, '//tips', "./tip[@id='examples']"),

		makeExample(0, 'Report on which functions are implemented', async () => {
			try {
				const result = await fetch('https://www.w3.org/TR/2017/REC-xpath-functions-31-20170321/function-catalog.xml');
				const text = await result.text();
				return text;
			} catch(error) {
				return '<xml>An unknown error has occured</xml>';
			}
		},
					'',
			`//fos:function[@prefix != "op"]/fos:signatures/fos:proto[
	function-lookup(xs:QName(../../@prefix || ":" || @name), count(fos:arg)) => exists()
]
/
(../../@prefix || ":" || @name || "@" || count(fos:arg))`
		)
	],
	'XQuery': [
		makeExample(
			1,
			'FLOWR',
			reusableLongerXml,
			'',
			`for $x in /bookstore/book
			  where $x/price>$maximumPrice
			return $x/title`,
			{ maximumPrice: 30 }
		),

		makeExample(
			1,
			'Creating a table',
			reusableSimpleXml,
			'',
			`<table>{
			  for $i in 1 to xs:int($data?rows)
			   return
			   <tr>{
			     for $j in 1 to xs:int($data?cols) return
			     <td><p>Cell at {$i},{$j}</p></td>
			   }</tr>
			}</table>`,
			{"data": {"cols": 10, "rows": 10}}
		),

		makeExample(
			1,
			'If, then, else',
			reusableLongerXml,
			'',
			`for $x in /bookstore/book
			  return if ($x/@category="CHILDREN")
			    then <child>{$x/node()}</child>
			    else <adult>{$x/node()}</adult>
			`,
			{ maximumPrice: 30 }
		)
	],
	'XQuery Update Facility': [
		makeExample(2, 'Delete a node', reusableSimpleXml, '', `delete node xml/title`),

		makeExample(
			2,
			'Insert a node',
			reusableSimpleXml,
			'',
			`insert node <a>I've been inserted.</a> into xml`
		),

		makeExample(
			2,
			'Insert multiple nodes',
			reusableSimpleXml,
			'',
			`insert nodes (<a/>, "you can use 'insert node' and 'insert nodes' interchangeably.", <b/>, <c/>) into xml`
		),

		makeExample(
			2,
			'Insert a node after a specific node',
			reusableSimpleXml,
			'',
			`insert node <a/> after //tip[@id = "examples"]`
		),

		makeExample(
			2,
			'Insert a node before a specific node',
			reusableSimpleXml,
			'',
			`insert node <a/> before //tip[@id = "examples"]`
		),

		makeExample(
			2,
			'Insert a node as the first node',
			reusableSimpleXml,
			'',
			`insert node <a/> as first into xml/tips`
		),

		makeExample(
			2,
			'Insert a node as the last node',
			reusableSimpleXml,
			'',
			`insert node <a/> as last into xml/tips`
		),

		makeExample(
			2,
			'Insert attributes',
			reusableSimpleXml,
			'',
			`insert nodes
				  (attribute tips {count(//tip)}, attribute foo {"bar"})
				  into descendant::element()[@id="examples"]`
		),

		makeExample(
			2,
			'Rename a node',
			reusableSimpleXml,
			'',
			`rename node xml/summary as "description"`
		),

		makeExample(
			2,
			'Replace the content of an element',
			reusableSimpleXml,
			'',
			`replace value of node xml/title with "fontoxpath playground"`
		),

		makeExample(
			2,
			'Replace a node',
			reusableLongerXml,
			'',
			`replace node //book[@category="COOKING"] with <archived />`
		),

		// Currently exhibits a bug with floats
		makeExample(
			2,
			'Replace multiple nodes',
			reusableLongerXml,
			'',
			`for $book in //book[price>35]
			  return replace node $book with <too-expensive excess="{$book/price - 35}">
			    {$book}
			  </too-expensive>`
		),

		makeExample(
			2,
			'Replace a node with a structure based on variables',
			'<replace-me />',
			'',
			`replace node //replace-me with <person-list>
			  { array:for-each($persons, function ($person) {
			    <person>
			      { map:for-each($person, function ($key, $value) {
			        <prop key="{ $key }">{ $value }</prop>
			      }) }
			    </person>
			  }) => array:flatten() }
			</person-list>`,
			{
				persons: [
					{ name: 'Anette Bananas', role: 'Editor' },
					{ name: 'Carl Dougherty', role: 'Author' }
				]
			}
		),

		makeExample(
			2,
			'Replace the value of an attribute',
			reusableSimpleXml,
			'',
			`replace value of node //@id[. = "edit"] with "foo"`
		),

		makeExample(
			2,
			'Replace the value of a comment, text node, and processing instruction',
			`<!-- My comment --><xml>My text node</xml><?my pi?>`,
			'',
			`replace value of node comment() with "foo",
			replace value of node xml/text() with "foo",
			replace value of node processing-instruction() with "foo"`
		)
	],
	'Fonto Specific Examples': [
		makeExample(
			3,
			'Use the fonto:dita-class function',
			reusableDitaXml,
			'',
			`declare namespace fonto="http://www.fontoxml.com/functions";

			(:
			 Note: this is not reflecting the actual implementation in Fonto;
			 the actual implementation requires schema knowledge
			:)
			declare function fonto:dita-class($node as node(), $class-name as xs:string) as xs:boolean {
			  (: Perform a simplified parse of the class attribute :)
			  tokenize($node/@class, ' ') = $class-name or
				trace(tokenize($class-name, '/')[last()]!normalize-space()) = name($node)
			};


			(: Change this to debug family declarations! :)
			fonto:dita-class(., 'topic/p')
`
		)
	]
};
