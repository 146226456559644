import React from 'react';
import { StateMessage, Text } from 'fds/components';
import { getBucketForSelector } from 'fontoxpath';

const noBucketMessage = (
	<Text>
		That selector is not bucketable. Only selectors that are like
		<code key="only"> self::element-name </code>
		are bucketable and benefit from proper optimization in Fonto CVK configuration. If you are
		planning to use this selector for CVK configuration, please consider another
	</Text>
);

/**
 * The contents of the panel that shows the bucket the selector resulted in
 */
export default function SandboxUpdatedXml({
	/**
	 * The input selector
	 *
	 * @type {string}
	 */
	xquery
}) {
	if (xquery) {
		let bucket;
		try {
			bucket = getBucketForSelector(xquery);
		} catch (error) {
			return null;
		}
		if (bucket === null) {
			return <StateMessage title="No bucket" visual="warning" message={noBucketMessage} />;
		}

		let summary;
		switch (bucket) {
			case 'type-1':
				summary = 'Only elements may match this selector';
				break;
			case 'type-1-or-type-2':
				summary = 'Only elements and attributes may match this selector';
				break;
			case 'type-2':
				summary = 'Only attribute nodes may match this selector';
				break;
			case 'type-7':
				summary = 'Only processing instruction nodes may match this selector';
				break;
			case 'type-8':
				summary = 'Only comment nodes may match this selector';
				break;
			case 'type-9':
				summary = 'Only document nodes may match this selector';
				break;
			default:
				if (bucket.startsWith('name')) {
					summary = `Only elements and attributes with the name ${bucket.substring(
						'name-'.length
					)} may match this selector. This is the most specific bucket at this time. Well done!`;
					break;
				}

				summary = '';
		}

		const mess = (
			<Text>
				This selector matches the
				<code> {bucket} </code>
				bucket. This means that Fonto CVK configuration will only evaluate this XPath for
				nodes that also have this bucket. {summary}.
			</Text>
		);
		return <StateMessage visual="fill-drip" title={bucket} message={mess} />;
	}

	return (
		<StateMessage
			title="No selector"
			visual="check"
			noBucketMessage={'Please write an XPath to determine the bucket it matches.'}
		/>
	);
}
