import {
	compressSync,
	decompressSync,
	strFromU8,
	strToU8
} from 'fflate';
import React, { useState, useEffect } from 'react';
import { sync } from 'slimdom-sax-parser';
import {
	App,
	Button,
	Flex,
	FontoLogo,
	Masthead,
	MastheadContent,
	MastheadToolbars,
	MastheadToolbar,
	MastheadToolbarButtons,
	SingleSelect
} from 'fds/components';

import playgroundModes from './configuration/playgroundModes';
import exampleStates from './configuration/exampleStates';

import SandboxExamplesButton from './components/SandboxExamplesButton';
import SandboxShareButton from './components/SandboxShareButton';

import { evaluateXPathToFirstNode } from 'fontoxpath';
import SandboxOutputPanel from './components/SandboxOutputPanel';
import SandboxInputPanel from './components/SandboxInputPanel';
import SandboxStatusbar from './components/SandboxStatusbar';

function parseXml(input) {
	try {
		return { input, parsed: sync(input, { position: true }) };
	} catch (e) {
		return { input, error: e.message };
	}
}

function parseVariables(input) {
	try {
		return { input, parsed: JSON.parse(input) };
	} catch (e) {
		return { input, error: e.message };
	}
}

function getInputFromLocation() {
	// Used if any number of query parameters are missing from the URL. Find this configuration and the examples in
	// ./configuration/exampleStates.js
	const defaultInitialState = exampleStates.default;

	// It's possible that a gbrowser does not support searchParams, in which case an error is logged and the defaults
	// are used.
	let urlInitialState = {};
	try {
		const url = new URL(window.location);
		if (url.searchParams.has('state')) {
			const compressedBuffer = Buffer.from(url.searchParams.get('state'), 'base64');
			const decompressedBuffer = decompressSync(compressedBuffer);
			urlInitialState = JSON.parse(strFromU8(decompressedBuffer));
		} else {
			urlInitialState = {
				mode: parseInt(url.searchParams.get('mode') || defaultInitialState.mode, 10),
				xml: url.searchParams.get('xml'),
				contextQuery: url.searchParams.get('context'),
				xQuery: url.searchParams.get('xpath'),
				variables: url.searchParams.get('variables')
			};
		}
	} catch (e) {
		console.warn(
			'Could not set initial application state based on URL parameters: ' + e.message
		);
	}

	// Merge any of the props from window.location into a combined state if they are not null or undefined
	return Object.keys(defaultInitialState).reduce(
		(state, key) => ({
			...state,
			[key]:
				urlInitialState[key] === null || urlInitialState[key] === undefined
					? defaultInitialState[key]
					: urlInitialState[key]
		}),
		{}
	);
}

async function getEvaluatedStateForInput(inputState) {
	// Do the initial parsing/validation immediately, though not for the
	const xml = typeof inputState.xml === 'string' ? inputState.xml : await inputState.xml();
	const parsedXml = parseXml(xml);
	const parsedVariables = parseVariables(inputState.variables);

	return {
		dom: parsedXml.parsed,
		react: {
			...inputState,
			xml,
			xmlError: parsedXml.error,
			variablesError: parsedVariables.error
		}
	};
}

function joinWordsWithCommasAndWords(words, separatorWord = ' and ') {
	return words.reduce(
		(sentence, word, i) =>
			i === 0 ? word : sentence + (i === words.length - 1 ? separatorWord : ', ') + word,
		''
	);
}

export default function Sandbox() {
	const modes = playgroundModes.map(mode => ({
		label: mode.label,
		value: mode
	}));

	// Use input
	const [mode, setMode] = useState(0);
	const [variables, setVariables] = useState(null);
	const [xml, setXml] = useState(null);
	const [xquery, setXQuery] = useState('');
	const [contextQuery, setContextQuery] = useState(null);
	const [evaluateAuto, setEvaluateAuto] = useState(true);

	// Errors
	const [variablesError, setVariablesError] = useState(null);
	const [xmlError, setXmlError] = useState(null);
	const [xqueryError, setXQueryError] = useState(null);
	const [xqueryErrorPosition, setXQueryErrorPosition] = useState(null);
	const [contextQueryError, setContextQueryError] = useState(null);

	// Results from running XPath/XQuery
	const [pendingUpdateList, setPendingUpdateList] = useState([]);
	const [updatedXml, setUpdatedXml] = useState(null);
	const [xdmValue, setXdmValue] = useState([]);
	const [dependencyMap, setDependencyMap] = useState(null);

	const [dom, setDom] = useState(null);

	// Use this with setState immediately because it also prepares dom for reuse, which should be in sync with
	// state.xml
	const setStateForInput = async inputState => {
		const evaluatedState = await getEvaluatedStateForInput(inputState);
		setDom(evaluatedState.dom);
		setXmlError(evaluatedState.react.xmlError);
		setVariablesError(evaluatedState.react.variablesError);
		setXQuery(evaluatedState.react.xQuery);
		setXml(evaluatedState.react.xml);
		setContextQuery(evaluatedState.react.contextQuery);
		setMode(evaluatedState.react.mode);
		if (evaluatedState.react.mode.initialSelector) {
			setXQuery(evaluatedState.react.mode.initialSelector);
		}
		setVariables(evaluatedState.react.variables);
	};

	useEffect(() => {
		const { mode, xQuery, variables, xml, contextQuery } = getInputFromLocation();

		setStateForInput({
			xml,
			variables,
			xQuery,
			mode,
			contextQuery
		});
	}, []);

	const getMode = () => {
		if (playgroundModes[mode]) {
			return playgroundModes[mode];
		}
		setMode(0);
		return playgroundModes[0];
	};

	const onExampleChange = example => {
		setStateForInput(example);
	};

	const autoEvaluate = () => {
		if (!evaluateAuto) {
			setUpdatedXml(null);
			setXdmValue(null);
			setPendingUpdateList([]);
			setDependencyMap(null);
			return Promise.resolve();
		}

		return evaluate();
	};

	const evaluate = () => {
		if (xmlError || variablesError) {
			setXdmValue([]);
			console.warn('Not evaluating due to error');
			return Promise.reject();
		}

		if (!xquery) {
			return Promise.reject();
		}

		const mode = getMode();

		const contextDom = mode.requireDomClone ? sync(xml) : dom;

		// Determine if an inputted context query should be used
		let context;
		try {
			setContextQueryError(null);
			context = contextQuery
				? evaluateXPathToFirstNode(contextQuery, contextDom)
				: contextDom;
		} catch (error) {
			setContextQueryError(error.message);
			context = contextDom;
		}
		return mode
			.evaluate(xquery, context, JSON.parse(variables))
			.then(({ xdmValue, pendingUpdateList, updatedXml, dependencyMap }) => {
				setPendingUpdateList(pendingUpdateList || []);
				setUpdatedXml(updatedXml);
				setXdmValue(xdmValue);
				setXQueryError(null);
				setXQueryErrorPosition(null);
				setDependencyMap(dependencyMap);
			})
			.catch(error => {
				setXQueryError(error.message);
				if (error.position) {
					setXQueryErrorPosition({
						from: {
							line: error.position.start.line - 1,
							ch: error.position.start.column - 1
						},
						to: {
							line: error.position.end.line - 1,
							ch: error.position.end.column - 1
						}
					});
				} else {
					setXQueryErrorPosition(null);
				}
			});
	};

	useEffect(() => {
		autoEvaluate();
	}, [dom, xml, xquery, variables, contextQuery, evaluateAuto, mode]);

	const onXQueryChange = newXQuery => {
		if (newXQuery === xquery) {
			return;
		}

		setXQuery(newXQuery);
	};

	const onVariablesChange = newVariables => {
		if (newVariables === variables) {
			return;
		}

		const parsed = parseVariables(newVariables);
		setVariablesError(parsed.error);
		setVariables(parsed.input);
	};

	const onXmlChange = newXml => {
		if (newXml === xml) {
			return;
		}
		const parsed = parseXml(newXml);

		setXmlError(parsed.error);
		setXml(parsed.input);

		setDom(parsed.parsed);
	};

	const onContextChange = newContextQuery => {
		if (newContextQuery === contextQuery) {
			return;
		}

		setContextQuery(newContextQuery);
	};

	// Creates an URL that contains hopefully all state information necessary to share.
	// For medium to big XML, the URL might be too restricted and the app will start with default content instead
	// The "xpath" query parameter is maintained to not break bookmarks anyone might have made to the old playground
	const getShareableUrl = () => {
		const newUrl = new URL(window.location);
		const stateString = JSON.stringify({
			mode,
			variables,
			xml,
			xQuery: xquery,
			contextQuery
		});
		const compressedBuffer = compressSync(strToU8(stateString), { level: 6, mem: 8 });
		newUrl.searchParams.set('state', Buffer.from(compressedBuffer).toString('base64'));

		return newUrl.href;
	};

	const getErrorMessage = () => {
		const hasErrorsIn = [];
		if (xmlError) {
			hasErrorsIn.push('XML');
		}

		if (contextQueryError) hasErrorsIn.push('context query');
		if (xqueryError) hasErrorsIn.push('XPath/XQuery');
		if (variablesError) hasErrorsIn.push('variables JSON');

		return hasErrorsIn.length
			? `You have ${
					hasErrorsIn.length === 1 ? 'an error' : 'errors'
			  } in your ${joinWordsWithCommasAndWords(hasErrorsIn)}`
			: null;
	};

	const errorMessage = getErrorMessage();

	return (
		<App>
			<Masthead>
				<MastheadContent>
					<Flex
						flexDirection="row"
						alignItems="center"
						applyCss={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Flex flex="0 0 auto">
							<FontoLogo onClick={() => window.open('https://www.fontoxml.com')} />
						</Flex>
						<Flex flex="0 0 auto">
							<SandboxExamplesButton
								examples={exampleStates}
								onSelect={onExampleChange}
							/>
							<SandboxShareButton createLink={getShareableUrl} />
						</Flex>
					</Flex>
				</MastheadContent>
				<MastheadToolbars>
					<MastheadToolbar>
						<MastheadToolbarButtons>
							<Flex flexDirection="row" alignItems="center" spaceSize="m">
								<SingleSelect
									items={modes.map(({ label }, value) => ({
										value,
										label
									}))}
									value={mode}
									onChange={modeId => {
										setMode(modeId);

										if (modes[modeId].value.initialXQuery) {
											setXQuery(modes[modeId].value.initialXQuery);
										}
									}}
								/>

								<Button
									label="Auto run"
									icon={evaluateAuto ? 'pause' : 'play'}
									isSelected={evaluateAuto}
									onClick={() => {
										setEvaluateAuto(!evaluateAuto);
									}}
									type="transparent"
								/>
								<Button
									label="Run once"
									icon="play"
									onClick={evaluate}
									isDisabled={evaluateAuto}
									type={'transparent'}
								/>
							</Flex>
						</MastheadToolbarButtons>
					</MastheadToolbar>
				</MastheadToolbars>
			</Masthead>

			<Flex flexDirection="row" flex="1 1" applyCss={{ zIndex: 1 }} spaceSize="s">
				<SandboxInputPanel
					xml={xml}
					xmlError={xmlError}
					xmlNodesToHighlight={xdmValue && xdmValue.filter(
						possiblyNode =>
							typeof possiblyNode === 'object' &&
							'nodeType' in possiblyNode &&
							'position' in possiblyNode
					)}
					onXmlChange={onXmlChange}
					contextQuery={contextQuery}
					contextQueryError={contextQueryError}
					onContextChange={onContextChange}
					xQuery={xquery}
					xQueryError={xqueryError}
					xQueryErrorPosition={xqueryErrorPosition}
					onXQueryChange={onXQueryChange}
					variables={variables}
					variablesError={variablesError}
					onVariablesChange={onVariablesChange}
					mode={mode}
				/>
				<SandboxOutputPanel
					xquery={xquery}
					errorMessage={errorMessage}
					mode={mode}
					xdmValue={xdmValue}
					dependencyMap={dependencyMap}
					pendingUpdateList={pendingUpdateList}
					updatedXml={updatedXml}
				/>
			</Flex>
			<SandboxStatusbar errorMessage={errorMessage} />
		</App>
	);
}
