// For more info: https://github.com/FontoXML/xmlprague-2017-soft-validation-presentation/blob/master/index.html#L224
export default function buildDomFacade(outputMap) {
	function addDep(type, node, bucket) {
		if (!node) {
			return;
		}

		var deps = outputMap.has(node) ? outputMap.get(node) : Object.create(null);
		deps[type] = 'type';
		if (bucket) {
			deps[bucket] = 'bucket';
		}

		outputMap.set(node, deps);
	}
	return new (class DependencyTrackingDomFacade {
		getParentNode(node, bucket) {
			if (node.nodeType === node.ATTRIBUTE_NODE) {
				addDep('attributes', node.ownerElement);
				return node.ownerElement;
			}
			addDep('childList', node.parentNode, bucket);
			return node.parentNode;
		}
		getFirstChild(node, bucket) {
			addDep('childList', node, bucket);
			return node.firstChild;
		}
		getNextSibling(node, bucket) {
			addDep('childList', node.parentNode, bucket);
			return node.nextSibling;
		}
		getPreviousSibling(node, bucket) {
			addDep('childList', node.parentNode, bucket);
			return node.previousSibling;
		}
		getLastChild(node, bucket) {
			addDep('childList', node, bucket);
			return node.lastChild;
		}
		getChildNodes(node, bucket) {
			addDep('childList', node, bucket);
			return node.childNodes;
		}
		getAttribute(node, name) {
			if (node.nodeType !== node.ELEMENT_NODE) {
				return null;
			}
			addDep('attributes', node);
			return node.getAttribute(name);
		}
		getAllAttributes(node, bucket) {
			if (node.nodeType !== node.ELEMENT_NODE) {
				return [];
			}
			addDep('attributes', node, bucket);
			return Array.from(node.attributes);
		}
		getData(node) {
			addDep('data', node);
			if (node.nodeType === node.ATTRIBUTE_NODE) {
				return node.value;
			}
			return node.data;
		}
	})();
}
