import React from 'react';
import { StateMessage } from 'fds/components';

import SandboxCodeMirror from './SandboxCodeMirror';

/**
 * The contents of the panel that shows the syntax-highlighted XML after an XQuery
 */
export default function SandboxUpdatedXml({
	/**
	 * An XML string
	 *
	 * @optional
	 * @type {string}
	 */
	value,

	/**
	 * Wether or not the XQuery Update Facility mode is active. If so, a different state message is shown
	 *
	 * @optional
	 * @type {boolean}
	 */
	isInUpdateFacility
}) {
	if (!isInUpdateFacility) {
		return (
			<StateMessage
				title="No pending updates"
				visual="info"
				connotation="info"
				message={'Updates are only generated if you use XQuery Update Facility'}
			/>
		);
	}
	if (value) {
		return <SandboxCodeMirror value={value} mode="xml" />;
	}

	return (
		<StateMessage
			title="No updated XML"
			visual="check"
			message={"That's weird, for someone who wants to use XQuery Update Facility"}
		/>
	);
}
