import React, { Component } from 'react';
import {
	Button,
	Flex,
	Label,
	Popover,
	PopoverAnchor,
	PopoverBody,
	PopoverFooter,
	TextArea
} from 'fds/components';

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
function copyToClipboard(str) {
	const currentSelection =
		document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);

	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);

	if (currentSelection) {
		document.getSelection().removeAllRanges();
		document.getSelection().addRange(currentSelection);
	}
}

/**
 * The "Permalink" button in the top right of the app. Shows a drop-down when clicked that has an URL that allows
 * users to share their input with others.
 */
class SandboxShareButton extends Component {
	state = {
		wasRecentlyCopied: false
	};

	revertButtonLabelTimeout = null;

	componentDidMount() {
		this.wasMounted = true;
	}

	componentWillUnmount() {
		this.wasMounted = true;
	}

	onCopyLink = event => {
		copyToClipboard(this.props.createLink());

		// Set the button label to something else for three seconds, then reset if component is still mounted
		this.setState(
			{
				wasRecentlyCopied: true
			},
			() => {
				if (this.revertButtonLabelTimeout) {
					clearTimeout(this.revertButtonLabelTimeout);
					this.revertButtonLabelTimeout = null;
				}
				setTimeout(() => {
					if (!this.wasMounted) {
						return;
					}
					this.setState({
						wasRecentlyCopied: false
					});
				}, 3000);
			}
		);
	};

	render() {
		return (
			<PopoverAnchor
				renderAnchor={({ onRef, togglePopover }) => (
					<Button label="Permalink" icon="link" onClick={togglePopover} onRef={onRef} />
				)}
				renderPopover={() => (
					<Popover>
						<PopoverBody>
							<Label>Share the URL to your code:</Label>
							<Flex applyCss={{ width: '300px' }}>
								<TextArea rows={5} value={this.props.createLink()} />
							</Flex>
						</PopoverBody>

						<PopoverFooter>
							<Flex flexDirection="row" spaceSize="m">
								<Button
									type="default"
									icon="external-link"
									label={'Go to'}
									onClick={() => (window.location = this.props.createLink())}
								/>
								<Button
									type="primary"
									icon="copy"
									label={
										this.state.wasRecentlyCopied
											? 'Copied!'
											: 'Copy to clipboard'
									}
									onClick={this.onCopyLink}
								/>
							</Flex>
						</PopoverFooter>
					</Popover>
				)}
			/>
		);
	}
}

export default SandboxShareButton;
