import React, { useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/xquery/xquery';

// Codemirror styling and some of our own sauce to make it play nice with surrounding flexbox
import 'codemirror/lib/codemirror.css';
import '../styles/codemirror.css';

import { HorizontalSeparationLine, Label } from 'fds/components';

import Toast from './Toast';

/**
 * Translate an offset in a string to a line / column combination
 *
 * Take string:
 * ```
 * abc\ndefg\n\nhijk
 * ```
 *
 * Take offset 6 (between 'e' and 'f'). The line/column combo is line 2, column 3
 */
function translateOffsetToCoordinate(offset, string) {
	let ch = 0;
	let line = 0;
	for (let i = 0; i < offset; ++i) {
		if (string[i] === '\n') {
			ch = 0;
			line++;
			continue;
		}
		ch++;
	}
	return {
		line,
		ch
	};
}

export default function SandboxCodeMirror({
	errorPosition,
	label,
	value,
	mode,
	onChange,
	highlightedPositions
}) {
	const [markers, setMarkers] = useState([]);
	const [editor, setEditor] = useState(null);

	useEffect(() => {
		const currentMarkers = markers.slice();
		currentMarkers.forEach(marker => marker.clear());

		if (editor) {
			const doc = editor.getDoc();
			if (errorPosition) {
				currentMarkers.push(
					doc.markText(errorPosition.from, errorPosition.to, {
						className: 'error-mark'
					})
				);
			}
			if (highlightedPositions) {
				highlightedPositions.forEach(highlightPosition => {
					const start = translateOffsetToCoordinate(highlightPosition.start, value);
					const end = translateOffsetToCoordinate(highlightPosition.end, value);

					currentMarkers.push(
						doc.markText(start, end, {
							className: 'highlight-mark'
						})
					);
				});
			}
		}
		setMarkers(currentMarkers);
	}, [errorPosition, highlightedPositions]);

	return (
		<Toast>
			{label && (
				<span>
					<Label>{label}</Label>
					<HorizontalSeparationLine />
				</span>
			)}

			<CodeMirror
				editorDidMount={editorInstance => {
					setEditor(editorInstance);
				}}
				value={value || ''}
				options={{ mode: mode, lineNumbers: true }}
				onBeforeChange={onChange ? (_editor, _data, value) => onChange(value) : null}
				onPaste={(_editor, event) => {
					const content = event.clipboardData.getData('application/xml');
					if (content) {
						event.preventDefault();
						onChange(`<xml>${content}</xml>`);
					}
				}}
			/>
		</Toast>
	);
}
