import React from 'react';
import { Flex, Icon, Statusbar, Text } from 'fds/components';

export default function SandboxStatusbar({ errorMessage }) {
	return (
		<Statusbar>
			<Flex direction="row" spaceSize="m">
				<Icon
					icon={errorMessage ? 'times' : 'check'}
					size="s"
					colorName={
						errorMessage ? 'state-message-error-color' : 'state-message-success-color'
					}
				/>
				<Text colorName={errorMessage ? 'state-message-error-color' : 'text-color'}>
					{errorMessage ? errorMessage : 'There are no errors'}
				</Text>
			</Flex>
		</Statusbar>
	);
}
