import React from 'react';

import { Flex, Icon, Text } from 'fds/components';

import SandboxCollapsible from './SandboxCollapsible';
import SandboxCodeMirror from './SandboxCodeMirror';

/**
 * A collapsible that can show error messages
 */
export default function SandboxCollapsibleWithDebug({
	label,
	/**
	 * The error message if there is an error
	 * @type {string}
	 */
	errorMessage,

	/**
	 * Whether or not the component is collapsed or expanded
	 * @type {boolean}
	 */
	isCollapsed,

	onChange,

	/**
	 * @optional
	 */
	children
}) {
	const iconColor = errorMessage ? 'state-message-error-color' : 'state-message-success-color';

	const textColor = errorMessage ? 'state-message-error-color' : 'text-color';

	const headerContent = (
		<Flex flexDirection="row" spaceSize="s">
			<Icon icon={errorMessage ? 'times' : 'check'} colorName={iconColor} />
			<Text colorName={textColor}>{label}</Text>
		</Flex>
	);

	return (
		<SandboxCollapsible
			headerContent={headerContent}
			isCollapsed={isCollapsed}
			onChange={onChange}
		>
			{children}
			{errorMessage && <SandboxCodeMirror value={errorMessage} label={'Error message'} />}
		</SandboxCollapsible>
	);
}
