import React from 'react';
import { KeyValueList, Label } from 'fds/components';

import XPathResult from './XPathResult';
import Toast from '../components/Toast';

/**
 * Render one update that was generated by fontoxpath#evaluateUpdatingExpression.
 */
export default function XQueryUpdate({
	/**
	 * @required
	 * @type {{ type: String, target: Node, replacement: Node[] }}
	 */
	update
}) {
	switch (update.type) {
		case 'delete':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Delete</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />
					}}
				/>
			);

		case 'insertAfter':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert after</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'insertAttributes':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert attributes</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'insertBefore':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert before</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'insertInto':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert into</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'insertIntoAsFirst':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert into as first</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'insertIntoAsLast':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Insert into as last</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Content: (
							<div>
								{update.content.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'rename':
			return (
				<KeyValueList
					valueByKey={{
						'Type': (
							<Toast invisible>
								<Label>Rename</Label>
							</Toast>
						),
						'Target': <XPathResult result={update.target} />,
						'New name': <XPathResult result={update.newName} />
					}}
				/>
			);

		case 'replaceElementContent':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Replace element content</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target.cloneNode(true)} />,
						Text: <XPathResult result={update.text} />
					}}
				/>
			);

		case 'replaceNode':
			return (
				<KeyValueList
					valueByKey={{
						Type: (
							<Toast invisible>
								<Label>Replace node</Label>
							</Toast>
						),
						Target: <XPathResult result={update.target} />,
						Replacements: (
							<div>
								{update.replacement.map((node, i) => (
									<XPathResult key={i} result={node} />
								))}
							</div>
						)
					}}
				/>
			);

		case 'replaceValue':
			return (
				<KeyValueList
					valueByKey={{
						'Type': (
							<Toast invisible>
								<Label>Replace value</Label>
							</Toast>
						),
						'Target': <XPathResult result={update.target} />,
						'String value': <XPathResult result={update['string-value']} />
					}}
				/>
			);

		default:
			return (
				<div>
					<Label>Unknown change type "{update.type}"</Label>
				</div>
			);
	}
}
