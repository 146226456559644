import React from 'react';
import { Flex, CollapsibleInlay } from 'fds/components';

/**
 * A collapsible for sections that could need a lot of space, but not always
 */
export default function SandboxCollapsible({
	/**
	 * The content shown at the top of the component
	 * @optional
	 */
	headerContent,

	/**
	 * Whether or not the component is collapsed or expanded
	 * @type {boolean}
	 */
	isCollapsed,

	onChange,

	/**
	 * The children of the component
	 * @optional
	 */
	children
}) {
	return (
		<CollapsibleInlay
			isCollapsed={isCollapsed}
			flex={isCollapsed ? 'none' : '1'}
			onChange={onChange}
			headerContent={headerContent}
		>
			{!isCollapsed && (
				<Flex
					flexDirection="row"
					spaceSize="m"
					applyCss={{ height: '100%', margin: '-4px', backgroundColor: '#fff' }}
				>
					{children}
				</Flex>
			)}
		</CollapsibleInlay>
	);
}
