import React from 'react';
import { StateMessage, Flex, Block } from 'fds/components';

import XPathResult from './XPathResult';

/**
 * The contents of the panel that shows XPath or XQuery results. Renders a list of those results, but any result can in
 * itself be an object, like a Node or an array
 */
export default function SandboxQueryResults({
	/**
	 * A list of results from an XPath or XQuery.
	 *
	 * @required
	 * @type {Array<*>}
	 */
	results
}) {
	if (results && results.length) {
		return (
			<Block applyCss={{ width: '100%' }}>
				<Flex flexDirection="column" spaceSize="m" flex="1">
					{results.map((result, i) => (
						<XPathResult result={result} key={i} />
					))}
				</Flex>
			</Block>
		);
	}

	return (
		<StateMessage
			title="No query results"
			visual="check"
			message={"That's not necessarily bad!"}
		/>
	);
}
