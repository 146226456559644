import React, { useState } from 'react';
import { Flex } from 'fds/components';

import SandboxCodeMirror from './SandboxCodeMirror';
import SandboxCollapsibleWithDebug from './SandboxCollapsibleWithDebug';

export default function SandboxInputPanel({
	xml,
	xmlError,
	xmlNodesToHighlight,
	onXmlChange,
	contextQuery,
	contextQueryError,
	onContextChange,
	xQuery,
	xQueryError,
	xQueryErrorPosition,
	onXQueryChange,
	variables,
	variablesError,
	onVariablesChange,
	mode
}) {
	const [isXmlCollapsed, setIsXmlCollapsed] = useState(false);
	const [isContextCollapsed, setIsContextCollapsed] = useState(true);
	const [isXQueryCollapsed, setIsXQueryCollapsed] = useState(false);
	const [isVariablesCollapsed, setIsVariablesCollapsed] = useState(true);

	xmlNodesToHighlight = xmlNodesToHighlight || [];

	const highlightedPositions = xmlNodesToHighlight.map(node=> {
		const position = node.position;
		return position
	});

	return (
		<Flex
			flexDirection="column"
			flex="1"
			applyCss={{
				zIndex: 2,
				overflow: 'hidden'
			}}
		>
			<SandboxCollapsibleWithDebug
				label="XML"
				errorMessage={xmlError}
				isCollapsed={isXmlCollapsed}
				onChange={() => setIsXmlCollapsed(!isXmlCollapsed)}
			>
				<SandboxCodeMirror
					mode="xml"
					value={xml}
					onChange={onXmlChange}
					label="XML input"
					highlightedPositions={highlightedPositions}
				/>
			</SandboxCollapsibleWithDebug>
			{mode !== 3 && <SandboxCollapsibleWithDebug
				label="Context query"
				errorMessage={contextQueryError}
				isCollapsed={isContextCollapsed}
				onChange={() => setIsContextCollapsed(!isContextCollapsed)}
			>
				<SandboxCodeMirror
					mode="xquery"
					value={contextQuery}
					onChange={onContextChange}
					label="Context query (leave empty for default)"
				/>
			</SandboxCollapsibleWithDebug>}
			<SandboxCollapsibleWithDebug
				label="XPath/XQuery"
				errorMessage={xQueryError}
				isCollapsed={isXQueryCollapsed}
				onChange={() => setIsXQueryCollapsed(!isXQueryCollapsed)}
			>
				<SandboxCodeMirror
					mode="xquery"
					value={xQuery}
					onChange={onXQueryChange}
					label="XPath/XQuery expression"
					errorPosition={xQueryErrorPosition}
				/>
			</SandboxCollapsibleWithDebug>

			<SandboxCollapsibleWithDebug
				label="Variables"
				errorMessage={variablesError}
				isCollapsed={isVariablesCollapsed}
				onChange={() => setIsVariablesCollapsed(!isVariablesCollapsed)}
			>
				<SandboxCodeMirror
					mode="javascript"
					value={variables}
					onChange={onVariablesChange}
					label="Variables (as JSON)"
				/>
			</SandboxCollapsibleWithDebug>
		</Flex>
	);
}
