import React from 'react';
import { ButtonWithDrop, Drop, Menu, MenuGroup, MenuItem } from 'fds/components';

export default function SandboxExamplesButton({ examples, onSelect }) {
	return (
		<ButtonWithDrop
			label="Examples"
			renderDrop={() => (
				<Drop>
					<Menu>
						{Object.keys(examples)
							.filter(category => Array.isArray(examples[category]))
							.map(category => (
								<MenuGroup key={category} heading={category}>
									{examples[category].map((example, i) => (
										<MenuItem
											key={i}
											label={example.label}
											onClick={() => onSelect(example)}
										/>
									))}
								</MenuGroup>
							))}
					</Menu>
				</Drop>
			)}
		/>
	);
}
