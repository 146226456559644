import {
	evaluateUpdatingExpression,
	evaluateXPathToAsyncIterator,
	evaluateXPath,
	executePendingUpdateList,
	evaluateXPathToBoolean,
	evaluateXPathToNodes
} from 'fontoxpath';

import { slimdom } from 'slimdom-sax-parser';

import buildDomFacade from './buildDomFacade';

/**
 * Determines the list of methods in which the user can evaluate input: as XPath, XQuery, XQuery Update Facility or Selector Testing.
 *
 * If you want to add a new option to the "Method" singleselect, you are looking at the right file.
 *
 * The order of items also determines the integer "mode" query parameter in the permalink.
 */

export default [
	{
		label: 'XPath 3.1',
		evaluate: async function(expression, dom, variables) {
			const dependencyMap = new Map();

			const xpathReturnValue = [];
			const asyncIterator = evaluateXPathToAsyncIterator(
				expression,
				dom,
				buildDomFacade(dependencyMap),
				variables,
				{
					language: evaluateXPath.XPATH_3_1_LANGUAGE,
					debug: true
				}
			);

			for (
				let item = await asyncIterator.next();
				!item.done;
				item = await asyncIterator.next()
			) {
				xpathReturnValue.push(item.value);
			}

			return { xdmValue: xpathReturnValue, dependencyMap };
		}
	},

	{
		label: 'XQuery 3.1',
		evaluate: async function(expression, dom, variables) {
			const dependencyMap = new Map();

			const xdmValue = [];
			const asyncIterator = evaluateXPathToAsyncIterator(
				expression,
				dom,
				buildDomFacade(dependencyMap),
				variables,
				{
					language: evaluateXPath.XQUERY_3_1_LANGUAGE,
					debug: true
				}
			);

			for (
				let item = await asyncIterator.next();
				!item.done;
				item = await asyncIterator.next()
			) {
				xdmValue.push(item.value);
			}

			return { xdmValue, dependencyMap };
		}
	},

	{
		label: 'XQuery Update Facility',

		// This means that XQueries are executed on a copy of the DOM so that the app state is not accidentally
		// overwritten.
		requireDomClone: true,

		evaluate: async function(expression, dom, variables) {
			const dependencyMap = new Map();

			const evaluationResult = await evaluateUpdatingExpression(
				expression,
				dom,
				buildDomFacade(dependencyMap),
				variables,
				{
					debug: true
				}
			);

			const deepClone = obj => {
				if (!obj || typeof obj === 'string') {
					return obj;
				}
				if (obj.nodeType) {
					return obj.cloneNode(true);
				}
				if (Array.isArray(obj)) {
					const clone = [];
					for (const key in obj) {
						clone[key] = deepClone(obj[key]);
					}
					return clone;
				}
				const clone = {};
				for (const key in obj) {
					clone[key] = deepClone(obj[key]);
				}
				return clone;
			};

			const clonedPendingUpdateList = [];
			for (const pendingUpdate of evaluationResult.pendingUpdateList) {
				clonedPendingUpdateList.push(deepClone(pendingUpdate));
			}

			executePendingUpdateList(evaluationResult.pendingUpdateList);

			const serializer = new slimdom.XMLSerializer();
			const updatedXml = [];
			for (const child of dom.childNodes) {
				updatedXml.push(serializer.serializeToString(child));
			}
			if (updatedXml.length === 0) {
				updatedXml.push('(Could not be displayed)');
			}

			const result = {
				xdmValue: evaluationResult.xdmValue.map(summary => summary.value),
				pendingUpdateList: clonedPendingUpdateList,
				updatedXml: updatedXml.join('\n'),
				dependencyMap
			};

			return result;
		}
	},
	{
		label: 'Selector Testing',
		initialXQuery: 'self::tip',
		evaluate: async function(expression, dom, variables) {
			const nodes = evaluateXPathToNodes('//node()', dom);
			const dependencyMap = new Map();
			const filteredNodes = nodes.filter(node =>
				evaluateXPathToBoolean(expression, node, buildDomFacade(dependencyMap), variables, {
					language: evaluateXPath.XQUERY_3_1_LANGUAGE
				})
			);

			return {
				xdmValue: filteredNodes,
				dependencyMap
			};
		}
	}
];
